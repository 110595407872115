import { createCheckoutSession } from "@stripe/firestore-stripe-payments";
import { auth } from "../services/Firebase";
import { payments } from "../services/stripe";

export const prices = {
  // priceYear: "price_1Mip3cAc62k7TGZsSfgD486Y", //R$ 99,90
  // priceYear: "price_1O0lRPAc62k7TGZsn5E1AIWY", // R$ 119,99
  priceYear: "price_1QSMlAAc62k7TGZsWEtPdFeU", // R$ 169,99
  priceMonth: "price_1Nwz5nAc62k7TGZsVlXhxnp5", // R$ 24,99
  // TEST MODE
  priceYearTest: "price_1LmPpZAc62k7TGZsjnVRMQ2z",
  priceMonthTest: "price_1Lz2cgAc62k7TGZsoTcaeIeB",
};

export async function goToPrice(priceId, setLoading) {
  const session = await createCheckoutSession(payments, {
    price: priceId,
    allow_promotion_codes: true,
    success_url:
      window.location.origin + `/welcome-plus-${auth?.currentUser?.uid}`,
    cancel_url: window.location.origin,
  }).catch((e) => {
    setLoading(false);
    console.log(e);
  });
  if (session) window.location.assign(session.url);
}
