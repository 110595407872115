import React from "react";
import "../../styles/Components/index.css";

const SwitchShowReceipt = ({ setOnShowReceipt, onShowReceipt }) => {
  const onChangeShowReceipt = async () => {
    setOnShowReceipt(!onShowReceipt);
  };

  return (
    <div
      style={{ width: 328 }}
      className="myne-modal__modal-edit-cadastro-content__show-catalogo myne-modal__modal-edit-cadastro-content__show-catalogo-big"
    >
      <label htmlFor={`show-receipt`} style={{ cursor: "pointer" }}>
        <p className="myne-text">
          Exibir <b>recibo personalizado</b>
        </p>
        <label className="switch switch-primary">
          <input
            style={{ cursor: "pointer" }}
            type="checkbox"
            onChange={() => onChangeShowReceipt()}
            id={`show-receipt`}
            checked={onShowReceipt}
          />
          <span style={{ cursor: "pointer" }} className="slider round"></span>
        </label>
      </label>
    </div>
  );
};

export default SwitchShowReceipt;
