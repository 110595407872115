import React from "react";

const ReceiptCustomCard = ({ companie }) => {
  const companiePhone = companie?.phone;
  const phone = `${companiePhone?.substring(0, 2)} ${companiePhone?.substring(
    2,
    companiePhone?.length
  )}`;

  return (
    <div className="myne-recibo-custom-card--companie">
      <div
        style={{
          backgroundImage: `url(${companie?.logo})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="myne-recibo-custom-card--companie__image"
      />

      <section>
        <h2 className="myne-text">{companie?.name}</h2>
        <p className="myne-text">
          {companie?.ddi} {phone}
        </p>
        <span className="myne-text">{companie?.address?.address1}</span>
      </section>
    </div>
  );
};

export default ReceiptCustomCard;
