export const langEn = {
  lang_pt: "Português",
  lang_en: "English",
  lang_es: "Español",
  language_change: "Language changed successfully",
  // LOGIN PAGE
  login: {
    titleMain: "Maximize Your Sales with <strong>Myne</strong>",
    descriptionMain: "The Ultimate Tool for Micro-Entrepreneurs",
    inputs: {
      email: "E-mail",
      password: "Password",
      forgot_password: "Forgot Password",
    },
    btns: {
      submit: "SIGN IN",
      submit_google: "SIGN IN WITH GOOGLE",
      create_account: "CREATE AN ACCOUNT",
      create_account_google: "CREATE WITH GOOGLE",
    },
    forgot_password_view: {
      title: "Forgot Password",
      submit: "Send",
      feedback: {
        title: "We have sent a password recovery email to the address:",
        submit: "OK",
      },
    },
    create_account_view: {
      title: "Create account",
      confirm_password: "Confirm Password",
      error_confirm_password: "Password does not equal confirmation!",
    },
    text_error: "Fill in all the fields!",
    text_error_email: "Fill in the email for password recovery!",
  },
  // LAYOUTS
  layouts: {
    sidebar: {
      links: {
        vendas: "Sales",
        registers: "Stock and registrations",
        options: "Options",
      },
      logout_btn: "Log out",
    },
    header: {
      filter_dates: {
        day: "DAY",
        month: "MONTH",
        day_plural: "Days",
        today: "Today",
        monthy_plural: "Months",
        datefilter_modal: {
          dayandinitial: "Initial day",
          dayandfinal: "Final day",
          daymonthinitial: "Initial month",
          daymonthfinal: "Final month",
        },
      },
      plus_btn: "BE PLUS",
    },
  },
  // ALERTS
  alerts: {
    update: "Updated successfully",
    register_sale: "Registered Sale",
    remove_sale: "Sale deleted successfully!",
    update_service: "Change successfully saved!",
    erro: "Something went wrong. Please try again later.",
    moeda_change: "Currency Updated Successful!",
    save_service: {
      alert: "",
      plural: "saved",
      no_plural: "saved",
      p2: "successfully!",
    },
    product_show_catalogo: "Product available in the online catalog",
    product_remove_catalogo: "Product removed from the online catalog",
    delete_service: "deleted successfully!",
    error_upload_image: "Error uploading image. Try again.",
  },
  // SALES
  sales: {
    title: "Sales",
    list_sales: {
      remove_sale: {
        title: "Delete sale",
        question: "Are you sure you want to delete the sale?",
      },
      no_itens: "Your sales will be registered here",
      title: "Sales registers",
      filter: {
        f1: "Total growing",
        f2: "Total decreasing",
        f3: "Date growing",
        f4: "Date decreasing",
      },
      item_sale: {
        status: {
          paid: "Paid",
          notpaid: "Unpaid",
          cash: "Cash",
          paid_day: "Payment on time",
          installments: "Installments",
          overdue: "Overdue",
        },
        sale_details: {
          title_receipt: "RECEIPT",
          title_clt: "Client",
          title_pdt: "Products",
          title_srv: "Services",
          checkout: {
            subtotal: "Subtotal",
            discount: "Discount",
            total: "Total",
          },
          btn_edit: "EDIT",
          export: {
            title: "Export",
            download: "Download receipt",
          },
          recibo: {
            sale_date: "Sale date:",
            infos_sale: {
              title: "Client",
              title_payment_sale: "Payment",
              title_status_sale: "Sale status",
            },
            tables: {
              title_pdt: "Products",
              title_srv: "Sevices",
              qty: "Amount",
              price: "Unit Price",
              total: "Total",
              checkout: {
                subtotal: "Subtotal",
                discount: "Discount",
                total_sale: "Total sale",
              },
            },
            installment_infos: {
              title: "Installment info",
              table: {
                t1: "Installment",
                t2: "Date",
                t3: "Status",
                t4: "Value",
              },
            },
            footer: {
              title: "Receipt generated by <b>Myne App.</b>",
            },
          },
        },
      },
      btn_add: "NEW SALE",
    },
    money_sales: {
      faturamento_brt: "Gross revenue",
      faturamento_liq: "Net revenue",
      tiket: "Average ticket",
    },
    biggestbuy_sales: {
      title: "Biggest Buyers",
      no_itens: "Here, the customers who buy the most will be displayed.",
    },
    graph_sales: {
      no_itens:
        "The chart will appear as soon as you register your first sale.",
      title_pdt: "Products Sales",
      title_clt: "Clients Sales",
      title_srv: "Services Sales",
      title_units: "Units",
      title_sales: "sales",
      btn_more: "View more",
    },
  },
  // BE PLUS
  be_plus: {
    title: "Grow your business with Myne Plus",
    // title_global: "Grow your business with <b>Myne Plus</b>",
    title_global: "<b>+100 Thousand businesses</b> worldwide",
    check1: "Unlimited sales",
    check2: "Myne for PC",
    check3: "No Ads",
    check4: "Amazing reports and graphs",
    check5: "Unlimited product categories",
    locked_plus:
      "This feature is locked for free users.<br />Become a <strong>PLUS user</strong> to Unlock this feature",
    limit_products: `The new limit for free users is <strong>${40} products</strong>`,
    limit:
      "You have reached the limit of <strong>1 daily sale</strong>.<br />To add more sales become a <strong>PLUS user.</strong>",
    btns: {
      plan: {
        title: "Plan",
        month: "month",
        cobra_year: "*Single charge of R$ 169,99 per year.",
        cobra_month: "*Billed Monthly.",
      },
      month: "Monthly",
      year: "Yearly",
      btn: "SUBSCRIBE",
    },
    // welcome
    welcome: {
      title: "Welcome to Myne Plus",
      desc: "We have confirmed your subscription and you now have full access to <b>Myne.</b>",
      check1: "Unlimited sales",
      check2: "Full access to future new features",
      check3: "No Ads",
    },
  },
  // OPTIONS
  options: {
    title: "Options",
    money: {
      title: "Currency",
      input: "Search...",
    },
    be_plus: "Be plus",
    catalog_online: {
      title: "My online catalog",
    },
    settings: {
      title: "Settings",
      account: {
        title: "Account",
        description: "Change data",
      },
      categories: {
        title: "Categories",
        description: "Configure the categories",
      },
      coin: {
        title: "Coin",
      },
      delete_account: {
        title: "Delete account",
        description: "Stop using Myne",
      },
      receipt: {
        title: "Receipt",
        description: "Customize receipt",
      },
      users: {
        title: "Users",
        description: "Create and manage",
        label_new: "NEW",
      },
    },
    batch_actions: {
      title: "Batch actions",
      products: "Products",
      customers: "Customers",
      description: {
        p1: "Add",
        p2: "in batch",
      },
    },
  },
  // MODALS
  modals: {
    // NEW SALE
    new_sale: {
      title: "NEW SALE",
      edit: "EDIT SALE",
      filter_btns: {
        f1: "DETAILS",
        f2: "CLOSURE",
      },
      details: {
        modals_btns: {
          input: "Search...",
          no_itens: {
            p1: "Your",
            p2: "registered will be shown here.",
          },
          discount_greater:
            "The discount cannot be greater than the sale price.",
          title_type: "New", // +type
          product: {
            modal_add: {
              title: "Add product to sale",
              unit_stock: "units in stock",
              price_unit: "Unit price",
              add_btn: "ADD",
            },
          },
        },
        input_desc: "Any Observation?",
        next_btn: "NEXT",
      },
      closure: {
        title_value_sale: "Sale value",
        checkout: {
          subtotal: "Subtotal",
          discount: {
            title: "Discount",
            btn: "ADD",
          },
          total: "Total",
        },
        //
        installments: "Installments",
        //
        installments_paid: "Have you already paid any installments?",
        //
        save_btn: "SAVE",
      },
    },
    // ADD IN LOTE
    modal_add_lote: {
      title: {
        p1: "Add",
        p2: "in batch",
      },
      import_file: {
        title: "Import file",
        download_file: {
          p1: "<b>Download the template file</b> and start registering your<br />",
          p2: "much more efficiently.",
        },
        download_btn: "Download",
        //
        file_sucess: {
          title: "File imported successfully.",
          cancel_file: "Cancel file",
        },
        drag_file: "Drag your file here or<br /><b>Select in your computer</b>",
        file_error: {
          title:
            "<b>Invalid file format.</b> Please, provide a file in<br /> XLSX format to proceed.",
          drag_file: "Drag your file here or<br />Select in your computer",
        },
        format:
          "Allowed format: <b>Excel (XLSX)</b> | Maximum size: <b>15 MB</b>",
      },
      review_dados: {
        title: "Review data",
        error: {
          p1: "errors were found.",
          p2: "Some mandatory fields may be empty or there are fields filled in incorrectly.",
        },
        alert:
          "<b>Review the imported data</b> and if anything goes wrong, go back to the previous<br />page, adjust the file and import again.",
      },
      next_btn: "NEXT",
      save_btn: "SAVE",
    },
  },
  // REGISTERS
  registers: {
    title: "Registers",
    filter_types: {
      pdt: "Products",
      clt: "Clients",
      srv: "Services",
      disc: "Discounts",
    },
    filter: {
      f1: "Name growing",
      f2: "Nome decreasing",
      f3: "Stock growing",
      f4: "Stock decreasing",
      f5: "Price growing",
      f6: "Price decreasing",
    },
    no_itens: {
      p1: "Your registered",
      p2: "will be shown here.",
    },
    title_registers: "Registered", // en: title_registers + type
    add_btn: {
      title: "NEW",
      add_lote: {
        p1: "Add",
        p2: "in batch",
      },
      add_manual: {
        p1: "Add",
      },
    },
    export_btn: "EXPORT STOCK",
    resume_stock: {
      title: "Stock summary",
      qty_pdt: "Quantity of Products",
      qty_stock: "Current stock quantity",
      value_stock: "Total stock cost value",
      based_price_cost: "(Base in products cost)",
      value_stock_sale: "Total stock price value",
      based_price_salwe: "(Base in products price)",
    },
    sales_clients: {
      no_click: "Click on the client and see their sales here.",
      title: "Sales by clients",
      total_sales: "Total number of sales",
      total_sold: "Total sold",
      sale_made: "Sale made on",
      no_itens: "No sales found for this client.",
    },
  },
  // TYPES
  services: {
    btn: {
      no: "NO",
      yes: "YES",
    },
    modal_new: "NEW",
    inputs: {
      required_fields: "Fill in the required fields",
      no_item: "Not filled",
      search: "Search",
      name: "Name", // *
      qty: "Quantity",
      price_cost: "Cost price",
      price_sale: "Sale price", // *
      stock_min: "Minimum stock",
      description: "Description",
      phone: "Phone",
      email: "E-mail",
      comment: "Coments",
    },
    modal_remove: {
      title: "Delete", // +type
      ask: "Are you sure you want to delete the", // ?
      ask2: "Are you sure you want to delete this", // ?
    },
    // PRODUTO
    product: {
      title: "Products",
      title_min: "Product",
      value: "Value",
      stock: "Stock",
      // ADD
      modal_add: {
        stock_min_value: "What is the minimum stock?",
        other_stock: "OTHER",
        photo: "Product photos",
        save_btn: "SAVE",
      },
      // STOCK
      modal_stock: {
        title: "Minimum stock",
      },
    },
    // CLIENT
    client: {
      title: "Clients",
      title_min: "Client",
      telephone: "Phone",
    },
    // SERVICE
    service: {
      title: "Services",
      title_min: "Service",
      value: "Value",
    },
    // CATEGORIE
    categories: {
      title: "Categories",
      title_min: "Categorie",
    },
    // DISCOUNT
    discount: {
      title: "Discounts",
      title_min: "Discount",
      qty: "Quantity",
      // ADD
      modal_add: {
        type: "What is the type of discount?",
        money: "Money",
        porcent: "Percentage",
        input_name: "Discount name", // *
        value_discount: "Discount amount",
      },
    },
  },
};
